html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
}

#appWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* TODO extract header and footer sizes */
#rootContainer {
  height: calc(100% - 124px);
  margin-top: 64px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 30px;
}

ul li::before {
  display: none;
}