.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
.green {
  background-color: #47ba99;
}
.yellow {
  background-color: #fff200;
}
.red {
  background-color: #f62a2a;
}
.orange {
  background-color: #f98b32;
}
ul li::before {
  content: '\2022';
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
